import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Overview/index.ts";
import { FaCloud as WebServiceIcon, FaDatabase as DatabaseIcon, FaJsSquare as JavaScriptClientIcon } from 'react-icons/fa';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const LinkGroupContainer = makeShortcode("LinkGroupContainer");
const LinkGroup = makeShortcode("LinkGroup");
const LinkGroupCard = makeShortcode("LinkGroupCard");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <p>{`Learn how to geolocate an IP address using GeoIP2 and GeoLite databases, or
GeoIP2 and GeoLite2 web services with server or client side API calls.`}</p>
    <LinkGroupContainer mdxType="LinkGroupContainer">
      <LinkGroup heading="Guides" mdxType="LinkGroup">
        <LinkGroupCard description="Geolocate an IP address using GeoIP2 or GeoLite2 databases." heading="Databases" icon={DatabaseIcon} to="/geoip/geolocate-an-ip/databases" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Geolocate an IP address using GeoIP2 or GeoLite2 web services using a server-side API call." heading="Web Services" icon={WebServiceIcon} to="/geoip/geolocate-an-ip/web-services" mdxType="LinkGroupCard" />
        <LinkGroupCard description="Geolocate an IP address using GeoIP2 or GeoLite2 web services using client-side JavaScript." heading="Client-side JavaScript" icon={JavaScriptClientIcon} to="/geoip/geolocate-an-ip/client-side-javascript" mdxType="LinkGroupCard" />
      </LinkGroup>
    </LinkGroupContainer>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      